<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div :id="anchorName">
    <h2 v-if="name" class="dynamic-main-title tmw-40-32-15">{{ name }}</h2>
    <v-container class="inner-container inner-container--content">
      <div class="customers-grid px-10 px-md-0">
        <div v-for="item in items" :key="item.id" class="customers-grid_item">
          <v-img
            :aspect-ratio="360 / 150"
            :src="getPhotoUrl(item)"
            :alt="photoName(item)"
          ></v-img>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import dynamicBlock from "@/components/dynamic/dynamicBlock.js";
export default {
  mixins: [dynamicBlock],
  methods: {
    getPhotoUrl(item) {
      return this.$helper.getPhotoUrl(item);
    },
    photoName(item) {
      if (!item) return "";
      return item.name;
    },
  },
};
</script>